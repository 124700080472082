import { ChevronDownIcon } from "@heroicons/react/24/outline"
import { AdjustmentsHorizontalIcon } from "@heroicons/react/24/solid"
import React, { useEffect, useRef, useState } from "react"
import Skeleton from "react-loading-skeleton"

import { Button } from "../components/shared/Buttons"
import { Divider } from "../components/shared/Layout"
import LoadingSpinner from "../components/shared/LoadingSpinner"
import { MobileFullScreenModal } from "../components/shared/Modal"
import { SearchPill } from "../components/shared/Pill"
import useWindowSize from "../hooks/useWindowSize"
import { updateLocationSearchText } from "../utils/utils"

import FiltersSection from "./SearchPage/FiltersSection"
import JoinInterstitial from "./SearchPage/JoinInterstitial"
import LocationSearchBox from "./SearchPage/LocationSearchBox"
import MapWithPins from "./SearchPage/MapWithPins"
import QuizInterstitial from "./SearchPage/QuizInterstitial"
import SearchCard from "./SearchPage/SearchCard"
import { useSearchPageContext, SearchPageProvider } from "./SearchPage/SearchPageContext"
import SearchPills from "./SearchPage/SearchPills"

const filtersSectionWidth = 312

const FallbackSection = ({ title, hits, keyPrefix, showDivider, showVirtualInsteadOfDistance = false }) => {
  const { isMobile } = useSearchPageContext()

  return (
    <>
      {!isMobile && showDivider && <Divider className="h-0.5 shadow" />}
      <h3 className="mb-6 font-bold text-gray-dark sm:my-4">{title}</h3>
      <ul>
        {hits.map((hit) => (
          <li key={keyPrefix + "-" + hit.objectID}>
            <SearchCard hit={hit} showVirtualInsteadOfDistance={showVirtualInsteadOfDistance} />
          </li>
        ))}
      </ul>
    </>
  )
}

const SearchPage = ({ loggedInAsPro }) => {
  const {
    isMobile,
    query,
    setQuery,
    radiusInMiles,
    setRadiusInMiles,
    selectedTherapies,
    setSelectedTherapies,
    selectedHealthIssues,
    setSelectedHealthIssues,
    selectedLocations,
    setSelectedLocations,
    selectedLanguages,
    selectedAges,
    showMap,
    setMapCenter,
    hits,
    nbHits,
    defaultLatLng,
    isLastPage,
    showMore,
    loading,
    mapCenter,
    googlePlacesLoaded,
    similarQueryFallbackState,
    nearbyFallbackState,
    virtualFallbackState,
    resetFilters,
    similarQueryFallbackSearch,
    nearbyFallbackSearch,
    virtualFallbackSearch,
    performedFallbackSearches,
    setPerformedFallbackSearches
  } = useSearchPageContext()

  const [mobileFiltersShown, setMobileFiltersShown] = useState(false)
  const [joinInterstitialDismissed, setJoinInterstitialDismissed] = useState(false)
  const [quizInterstitialDismissed, setQuizInterstitialDismissed] = useState(false)
  const sentinelRef = useRef(null)
  const searchResultsRef = useRef(null)
  const mobileFiltersRef = useRef(null)
  const containerRef = useRef(null)
  const { width } = useWindowSize()

  const filtersCount =
    selectedTherapies.length + selectedHealthIssues.length + selectedLanguages.length + selectedAges.length

  useEffect(() => {
    if (sentinelRef.current !== null) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (!isLastPage) {
              showMore()
            } else if (!performedFallbackSearches) {
              setPerformedFallbackSearches(true)
              if (query && !similarQueryFallbackState.queryCompleted) similarQueryFallbackSearch()
              if (!(selectedLocations.length === 1 && selectedLocations.includes("virtual"))) {
                if (!nearbyFallbackState.queryCompleted) nearbyFallbackSearch()
                if (!virtualFallbackState.queryCompleted) virtualFallbackSearch()
              }
            }
          }
        })
      })

      observer.observe(sentinelRef.current)

      return () => {
        observer.disconnect()
      }
    }
  }, [isLastPage, showMore])

  useEffect(() => {
    const onRefinementChanged = (e) => setQuery(e.detail.name)

    document.addEventListener("refinementChanged", onRefinementChanged)

    if (mapCenter === null) {
      setMapCenter(defaultLatLng)
      updateLocationSearchText([defaultLatLng.lat, defaultLatLng.lng])
    } else if (mapCenter) {
      updateLocationSearchText([mapCenter.lat, mapCenter.lng])
    }

    const onScroll = () => {
      if (mobileFiltersRef.current && containerRef.current) {
        if (window.scrollY >= 64) {
          mobileFiltersRef.current.style.top = 0
          mobileFiltersRef.current.style.left = 0
          mobileFiltersRef.current.style.right = 0
          mobileFiltersRef.current.style.paddingLeft = "24px"
          mobileFiltersRef.current.style.paddingRight = "24px"
          mobileFiltersRef.current.style.paddingBottom = "16px"
          mobileFiltersRef.current.style.position = "fixed"
          mobileFiltersRef.current.style.borderBottom = "1px solid rgb(var(--color-light-gray))"
          if (isMobile) {
            containerRef.current.style.paddingTop = "128px"
          }
        } else {
          mobileFiltersRef.current.style.position = "static"
          mobileFiltersRef.current.style.borderBottom = "none"
          mobileFiltersRef.current.style.paddingLeft = 0
          mobileFiltersRef.current.style.paddingRight = 0
          containerRef.current.style.paddingTop = 0
        }
      }
    }

    window.addEventListener("scroll", onScroll)

    return () => {
      document.removeEventListener("refinementChanged", onRefinementChanged)
      window.removeEventListener("scroll", onScroll)
    }
  }, [])

  useEffect(() => {
    if (width > 0 && width < 640) {
      document.body.classList.remove("has-navbar-fixed-top")
      document.querySelector("nav").classList.remove("fixed")
    } else {
      document.body.classList.add("has-navbar-fixed-top")
      document.querySelector("nav").classList.add("fixed")
    }
  }, [width])

  useEffect(() => {
    if (hits?.length === 0 && !performedFallbackSearches) {
      setPerformedFallbackSearches(true)
      if (query && !similarQueryFallbackState.queryCompleted) similarQueryFallbackSearch()
      if (!(selectedLocations.length === 1 && selectedLocations.includes("virtual"))) {
        if (!nearbyFallbackState.queryCompleted) nearbyFallbackSearch()
        if (!virtualFallbackState.queryCompleted) virtualFallbackSearch()
      }
    }
  }, [hits])

  let numberOfResults =
    nbHits + similarQueryFallbackState.nbHits + nearbyFallbackState.nbHits + virtualFallbackState.nbHits
  if (numberOfResults > 99) numberOfResults = "99+"

  return (
    <>
      <div ref={containerRef}>
        <div
          className="fixed z-30 h-screen min-h-100 overflow-y-auto border-r border-gray-light bg-white p-8 pb-[80px] md:hidden"
          style={{ width: filtersSectionWidth }}>
          <FiltersSection />
        </div>
        <div
          ref={searchResultsRef}
          className={`min-h-screen overflow-y-auto bg-gray-ultralight p-8 sm:w-full sm:bg-white sm:p-6 sm:pt-0 ${
            showMap ? "min_search_tablet:w-[calc(50%-156px)] min_search_tablet:min-w-[650px]" : ""
          }`}
          style={{
            marginLeft: isMobile ? 0 : filtersSectionWidth,
            width: showMap ? null : "100% !important"
          }}>
          <div className="-mx-6 mb-6 hidden border-b border-gray-light px-6 sm:block">
            <div className="z-10 bg-white pb-4" ref={mobileFiltersRef}>
              <div className="flex items-center justify-between gap-4 py-4">
                <div className="w-full">
                  {googlePlacesLoaded ? <LocationSearchBox /> : <Skeleton className="h-8 w-full" />}
                </div>
                <Button
                  type={filtersCount > 0 ? "secondary" : "tertiary"}
                  onClick={() => setMobileFiltersShown(true)}
                  className="flex h-10 flex-none items-center gap-2">
                  <AdjustmentsHorizontalIcon className="h-5 w-5" />
                  <span>{`Filters${filtersCount > 0 ? ` (${filtersCount})` : ""}`}</span>
                </Button>
              </div>
              <div className="-mr-6 flex gap-4 overflow-x-auto">
                {["Services", "Health issues", "Locations", "Languages", "Client ages"].map((filter) => (
                  <Button
                    type="tertiary"
                    key={filter}
                    onClick={() => setMobileFiltersShown(true)}
                    className="flex h-10 flex-none items-center gap-2">
                    <span>{filter}</span>
                    <ChevronDownIcon className="h-5 w-5" />
                  </Button>
                ))}
              </div>
            </div>
          </div>
          <div>
            <div className="mb-4 flex justify-between sm:mb-6">
              <div className="flex gap-2 sm:-mr-6 sm:overflow-x-auto min_sm:flex-wrap">
                {query && (
                  <SearchPill
                    onCloseButtonClick={() => {
                      setQuery("")
                      document.dispatchEvent(new CustomEvent("resetSearchField"))
                    }}>
                    &quot;{query}&quot;
                  </SearchPill>
                )}
                <SearchPills
                  selectedItems={selectedLocations}
                  setSelectedItems={setSelectedLocations}
                  mapping={{
                    virtual: "Virtual",
                    office: "At a practice",
                    home: "At my home"
                  }}
                />
                {radiusInMiles && (
                  <SearchPill onCloseButtonClick={() => setRadiusInMiles(null)}>
                    Within {radiusInMiles} miles
                  </SearchPill>
                )}
                <SearchPills selectedItems={selectedTherapies} setSelectedItems={setSelectedTherapies} />
                <SearchPills selectedItems={selectedHealthIssues} setSelectedItems={setSelectedHealthIssues} />
              </div>
            </div>
            <ul>
              {hits?.map((hit, i) => (
                <React.Fragment key={hit.objectID}>
                  {i === 5 && !loggedInAsPro && !joinInterstitialDismissed && (
                    <li className="mb-8 sm:-mx-6 sm:mb-0 sm:border-b sm:border-gray-light sm:p-6">
                      <JoinInterstitial onDismiss={() => setJoinInterstitialDismissed(true)} />
                    </li>
                  )}
                  {i === 10 && !loggedInAsPro && !quizInterstitialDismissed && (
                    <li className="mb-8 sm:-mx-6 sm:mb-0 sm:border-b sm:border-gray-light sm:p-6">
                      <QuizInterstitial onDismiss={() => setQuizInterstitialDismissed(true)} />
                    </li>
                  )}
                  <li>
                    <SearchCard hit={hit} />
                  </li>
                </React.Fragment>
              ))}
              {hits?.length > 0 && <li ref={sentinelRef} aria-hidden="true" />}
            </ul>
            {similarQueryFallbackState.nbHits > 0 && (
              <FallbackSection
                title={`${nbHits > 0 ? "More practitioners" : "Practitioners"} offering services similar to ${query}`}
                hits={similarQueryFallbackState.hits}
                keyPrefix="similarQuery"
                showDivider={nbHits > 0}
              />
            )}
            {nearbyFallbackState.nbHits > 0 && (
              <FallbackSection
                title={`${
                  nearbyFallbackState.nbHits === 1 ? "One more practitioner" : "More practitioners"
                } found nearby`}
                hits={nearbyFallbackState.hits}
                keyPrefix="nearbyFallback"
                showDivider={nbHits > 0 || similarQueryFallbackState.nbHits > 0}
              />
            )}
            {virtualFallbackState.nbHits > 0 && (
              <FallbackSection
                title={`${
                  nbHits > 0 || similarQueryFallbackState.nbHits > 0 || nearbyFallbackState.nbHits > 0
                    ? "More practitioners"
                    : "Practitioners"
                } offering virtual services`}
                hits={virtualFallbackState.hits}
                keyPrefix="virtualFallback"
                showDivider={nbHits > 0 || similarQueryFallbackState.nbHits > 0 || nearbyFallbackState.nbHits > 0}
                showVirtualInsteadOfDistance={true}
              />
            )}
            {loading ||
            similarQueryFallbackState.fetching ||
            nearbyFallbackState.fetching ||
            virtualFallbackState.fetching ? (
              <LoadingSpinner className="py-0" />
            ) : nbHits === 0 &&
              similarQueryFallbackState.nbHits === 0 &&
              nearbyFallbackState.nbHits === 0 &&
              virtualFallbackState.nbHits === 0 ? (
              <div className="mt-8 font-bold text-gray-dark">
                No results found for {query ? <span>&quot;{query}&quot;</span> : "your search"}. Try removing some
                filters or try a different search.
              </div>
            ) : null}
          </div>
        </div>
        {showMap && (
          <div
            className="fixed bottom-0 right-0 top-[80px] z-30 w-[calc(50%-156px)] border-l border-gray-light"
            style={{ maxWidth: "calc(100vw - 962px)" }}>
            {showMap ? <MapWithPins /> : <div id="map" className="h-full w-full" />}
          </div>
        )}
        {isMobile && (
          <MobileFullScreenModal
            hideModal={() => {
              setMobileFiltersShown(false)
              setTimeout(() => {
                window.scrollTo(0, 0)
              }, 100)
            }}
            visible={mobileFiltersShown}
            header="Filters"
            showFooter={true}
            animate={false}
            actionButtonCopy={`Show ${numberOfResults} results`}
            onSave={() => {
              setMobileFiltersShown(false)
              setTimeout(() => {
                window.scrollTo(0, 0)
              }, 100)
            }}
            cancelButtonCopy="Reset"
            onCancel={() => {
              resetFilters()
              setMobileFiltersShown(false)
              setTimeout(() => {
                window.scrollTo(0, 0)
              }, 100)
            }}
            unmount={false}>
            <FiltersSection />
          </MobileFullScreenModal>
        )}
      </div>
    </>
  )
}

export default function Page({
  latLng,
  therapyCategories,
  healthIssues,
  languages,
  ages,
  loggedInAsPro,
  searchParams,
  isMobile,
  isTablet
}) {
  return (
    <SearchPageProvider
      mobile={isMobile}
      tablet={isTablet || isMobile}
      therapyCategories={therapyCategories}
      healthIssues={healthIssues}
      defaultLatLng={latLng}
      languages={languages}
      ages={ages}
      searchParams={searchParams}>
      <SearchPage loggedInAsPro={loggedInAsPro} />
    </SearchPageProvider>
  )
}
