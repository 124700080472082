import React from "react"

import { CheckBox } from "../../components/shared/Inputs"

import { useSearchPageContext } from "./SearchPageContext"

const locationName = (location) => {
  if (location === "office") {
    return "At a practice"
  } else if (location === "home") {
    return "At my home"
  } else if (location === "virtual") {
    return "Virtual"
  }
}

const LocationFilters = () => {
  const { locationOptions, selectedLocations, setSelectedLocations } = useSearchPageContext()

  return (
    <>
      <ul className="mt-3 flex flex-col gap-1">
        {locationOptions.map((item) => (
          <li key={item}>
            <CheckBox
              className="mb-0"
              checked={selectedLocations.includes(item)}
              onChange={() => {
                setSelectedLocations((locations) => {
                  if (locations.includes(item)) {
                    return locations.filter((location) => location !== item)
                  } else {
                    return [...locations, item]
                  }
                })
              }}
              label={locationName(item)}
            />
          </li>
        ))}
      </ul>
    </>
  )
}

export default LocationFilters
