import { Combobox } from "@headlessui/react"
import { MapPinIcon } from "@heroicons/react/24/solid"
import capitalize from "lodash/capitalize"
import React, { useEffect, useState } from "react"
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService"
import { twMerge } from "tailwind-merge"

import { BASE_INPUT_CLASSNAMES, DROPDOWN_CLASSNAMES, DROPDOWN_OPTION_CLASSNAMES } from "../../components/shared/Inputs"
import LoadingSpinner from "../../components/shared/LoadingSpinner"
import useWindowSize from "../../hooks/useWindowSize"
import { stripUSAFromString } from "../../utils/utils"

const LocationSearchBox = () => {
  const { width } = useWindowSize()
  const isMobile = width <= 768
  const [inputText, setInputText] = useState("")

  const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } = usePlacesService({
    debounce: 100,
    options: {
      types: ["locality", "administrative_area_level_3", "postal_code"]
    }
  })

  useEffect(() => {
    const onNewLocationChosen = (e) => setInputText(e.detail)
    document.addEventListener("newLocationChosen", onNewLocationChosen)

    return () => {
      document.removeEventListener("newLocationChosen", onNewLocationChosen)
    }
  }, [])

  return (
    <Combobox
      as="div"
      className="sm:flex-1"
      value={inputText}
      onChange={(item) => {
        if (item) {
          placesService.getDetails({ placeId: item.place_id }, (place) => {
            const newMapCenter = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() }
            const event = new CustomEvent("newMapCenter", { detail: newMapCenter })
            document.dispatchEvent(event)
            setInputText(stripUSAFromString(item.description))
          })
        }
      }}>
      <div className="relative">
        <Combobox.Input
          onChange={(evt) => {
            setInputText(evt.target.value)
            getPlacePredictions({ input: evt.target.value })
          }}
          className={twMerge(BASE_INPUT_CLASSNAMES, "sm:py-[7px] min_sm:w-[260px] min_sm:rounded-l-none")}
          placeholder="Location"
        />
        {isPlacePredictionsLoading ? (
          <LoadingSpinner className="absolute right-2 top-2 py-0" spinnerClassName="h-5 w-5" />
        ) : (
          <MapPinIcon className="absolute right-1.5 top-1.5 h-6 w-6 text-gray-dark opacity-[0.64] sm:top-[7px]" />
        )}
      </div>
      {placePredictions?.length > 0 && !isPlacePredictionsLoading && (
        <Combobox.Options
          className={twMerge(DROPDOWN_CLASSNAMES, "fixed w-[260px] sm:w-[calc(100%-48px)]")}
          style={{ maxWidth: isMobile ? 300 : 260 }}>
          {placePredictions.map((item) => (
            <Combobox.Option key={item.description} className={DROPDOWN_OPTION_CLASSNAMES} value={item}>
              <div
                dangerouslySetInnerHTML={{
                  __html: stripUSAFromString(item.description).replace(
                    new RegExp(inputText, "i"),
                    `<mark style='font-weight: bold'>${capitalize(inputText)}</mark>`
                  )
                }}
              />
            </Combobox.Option>
          ))}
        </Combobox.Options>
      )}
    </Combobox>
  )
}

export default LocationSearchBox
