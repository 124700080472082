import React from "react"

import { Button } from "../../components/shared/Buttons"
import { Label, Toggle } from "../../components/shared/Inputs"
import { RadioWithLabel } from "../../components/shared/RadioButtons"

import FacetList from "./FacetList"
import HealthIssuesFacetList from "./HealthIssuesFacetList"
import LocationFilters from "./LocationFilters"
import RefinementPanel from "./RefinementPanel"
import { useSearchPageContext } from "./SearchPageContext"
import TherapiesFilters from "./TherapiesFilters"

const distanceMarks = [
  { value: 2, label: "2mi" },
  { value: 5, label: "5mi" },
  { value: 10, label: "10mi" },
  { value: 25, label: "25mi" },
  { value: 50, label: "50mi" },
  { value: 100, label: "100mi" }
]

const FiltersSection = () => {
  const {
    radiusInMiles,
    setRadiusInMiles,
    isTablet,
    offersFreeConsult,
    setOffersFreeConsult,
    selectedTherapies,
    selectedHealthIssues,
    languages,
    selectedLanguages,
    setSelectedLanguages,
    languageCounts,
    ages,
    selectedAges,
    setSelectedAges,
    ageCounts,
    resetFilters
  } = useSearchPageContext()

  const defaultFilterState =
    !offersFreeConsult &&
    selectedTherapies.length === 0 &&
    selectedHealthIssues.length === 0 &&
    selectedAges.length === 0 &&
    selectedLanguages.length === 0

  return (
    <>
      {!defaultFilterState && (
        <Button type="tertiary" className="mb-6 w-full sm:hidden" onClick={resetFilters}>
          Reset filters
        </Button>
      )}
      <div className="mb-8 flex cursor-pointer items-center justify-between">
        <Label className="text-base" htmlFor="toggle-free-consult">
          Offers free consult
        </Label>
        <Toggle
          id="toggle-free-consult"
          checked={offersFreeConsult}
          onChange={() => setOffersFreeConsult(!offersFreeConsult)}
        />
      </div>
      <RefinementPanel title="Filter by therapy">
        <TherapiesFilters />
      </RefinementPanel>
      <RefinementPanel title="Filter by health issue">
        <HealthIssuesFacetList />
      </RefinementPanel>
      <RefinementPanel title="Location of service">
        <LocationFilters />
      </RefinementPanel>
      {isTablet && (
        <RefinementPanel title="Show practitioners within">
          <div className="mt-2 flex flex-col gap-2">
            {distanceMarks.map((mark) => (
              <RadioWithLabel
                id={`distance-radio-${mark.value}`}
                key={`distance-radio-${mark.value}`}
                value={mark.value}
                name="radius"
                label={mark.label}
                checked={radiusInMiles === mark.value}
                onChange={() => setRadiusInMiles(mark.value)}
              />
            ))}
          </div>
        </RefinementPanel>
      )}
      <RefinementPanel title="Languages spoken" expanded={selectedLanguages.length > 0}>
        <FacetList
          items={languages}
          setSelectedItems={setSelectedLanguages}
          selectedItems={selectedLanguages}
          counts={languageCounts}
        />
      </RefinementPanel>
      <RefinementPanel title="Client age(s)" expanded={selectedAges.length > 0}>
        <FacetList
          items={ages}
          setSelectedItems={setSelectedAges}
          selectedItems={selectedAges}
          counts={ageCounts}
          alwaysShowAll={true}
        />
      </RefinementPanel>
    </>
  )
}

export default FiltersSection
