import React, { useState, memo } from "react"

import { CheckBox, SearchInput } from "../../components/shared/Inputs"

import { useSearchPageContext } from "./SearchPageContext"

const HealthIssuesFacetList = () => {
  const { healthIssues, setSelectedHealthIssues, selectedHealthIssues, healthIssueCounts } = useSearchPageContext()
  const [filteredItems, setFilteredItems] = useState(healthIssues)
  const [numShown, setNumShown] = useState(4)
  const [searchValue, setSearchValue] = useState("")
  const canToggleShowMore = filteredItems.length > 4

  const shownHealthIssues = filteredItems
    .filter((item) => (searchValue === "" && healthIssueCounts ? healthIssueCounts[item] > 0 : true))
    .sort((a, b) => {
      const aSelected = selectedHealthIssues.includes(a) ? 1 : 0
      const bSelected = selectedHealthIssues.includes(b) ? 1 : 0

      if (aSelected !== bSelected) return bSelected - aSelected

      if (healthIssueCounts) {
        return (healthIssueCounts[b] || 0) - (healthIssueCounts[a] || 0)
      } else {
        return a.localeCompare(b)
      }
    })

  return (
    <>
      <SearchInput
        type="search"
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck={false}
        maxLength={512}
        containerClassName="w-full"
        explanatorySubtext="Ex “Low back pain” or “Joint pain”"
        value={searchValue}
        onChange={(event) => {
          const newItems = healthIssues.filter((item) => item.toLowerCase().includes(event.target.value.toLowerCase()))
          setFilteredItems(newItems)
          setSearchValue(event.target.value)
        }}
      />
      <ul className="mt-4 flex flex-col gap-1.5">
        {shownHealthIssues.slice(0, numShown).map((item) => (
          <li key={item}>
            <CheckBox
              id={`health-issue-${item}`}
              className="mb-0"
              checked={selectedHealthIssues.includes(item)}
              onChange={() => {
                setSelectedHealthIssues((prevItems) => {
                  if (prevItems.includes(item)) {
                    return prevItems.filter((prevItem) => prevItem !== item)
                  } else {
                    return [...prevItems, item]
                  }
                })
              }}
              label={
                healthIssueCounts && healthIssueCounts[item] > 0
                  ? `${item} (${healthIssueCounts[item] > 99 ? "99+" : healthIssueCounts[item]})`
                  : item
              }
            />
          </li>
        ))}
      </ul>
      {canToggleShowMore && (
        <button
          onClick={() => (numShown < shownHealthIssues.length ? setNumShown(numShown + 10) : setNumShown(4))}
          className="mt-1.5 font-bold text-teal">
          {numShown < shownHealthIssues.length ? "Load 10 more" : "See less"}
        </button>
      )}
    </>
  )
}

export default memo(HealthIssuesFacetList)
