import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/24/outline"
import React, { useState } from "react"

const RefinementPanel = ({ title, children, expanded = true }) => {
  const [isExpanded, setIsExpanded] = useState(expanded)

  return (
    <div className="pb-8">
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="mb-1 flex w-full items-center justify-between font-bold">
        <span>{title}</span>
        <span>{isExpanded ? <ChevronUpIcon className="h-5 w-5" /> : <ChevronDownIcon className="h-5 w-5" />}</span>
      </button>
      <div className={isExpanded ? "" : "hidden"}>{children}</div>
    </div>
  )
}

export default RefinementPanel
