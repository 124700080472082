import React from "react"

import { SearchPill } from "../../components/shared/Pill"

const SearchPills = ({ selectedItems, setSelectedItems, mapping }) => (
  <>
    {selectedItems.map((item) => (
      <SearchPill key={item} onCloseButtonClick={() => setSelectedItems(selectedItems.filter((t) => t !== item))}>
        {mapping && mapping[item] ? mapping[item] : item.replace(/\s*\(.*\)$/, "")}
      </SearchPill>
    ))}
  </>
)

export default SearchPills
