import { EllipsisVerticalIcon, XMarkIcon } from "@heroicons/react/24/outline"
import clsx from "clsx"
import PropTypes from "prop-types"
import React from "react"
import { twMerge } from "tailwind-merge"

const Pill = ({ children, onClick, type = "primary" }) => (
  <div
    className={clsx(
      "flex h-8 min-w-fit max-w-fit items-center whitespace-nowrap border border-gray px-4 py-1 text-center hover:cursor-pointer hover:border-black",
      { "bg-white": type === "primary" },
      { "border-none bg-offwhite": type === "secondary" },
      { "border-teal bg-teal text-white": type === "tertiary" }
    )}
    style={{ borderRadius: "50px" }}
    onClick={onClick}>
    {children}
  </div>
)

Pill.propTypes = {
  type: PropTypes.oneOf(["primary", "secondary", "tertiary"]),
  children: PropTypes.node,
  onClick: PropTypes.func
}

export default Pill

export const EndorsementPill = ({ children, draggable, active, className, onCloseButtonClick, ...rest }) => {
  const Tag = draggable ? "div" : "button"

  return (
    <Tag
      className={twMerge(
        "flex items-center justify-center rounded border border-gray px-3 py-1 hover:border-black focus:outline-none focus:ring-2 focus:ring-blue",
        active ? "border-gray-dark bg-gray-dark font-bold text-white hover:border-gray-dark" : "bg-white",
        draggable ? "cursor-grab" : "cursor-pointer",
        className
      )}
      {...rest}>
      {draggable && <EllipsisVerticalIcon className="-ml-2 h-5 w-5 stroke-2">{draggable}</EllipsisVerticalIcon>}
      {children}
      {draggable && (
        <button onClick={onCloseButtonClick}>
          <XMarkIcon className="z-10 ml-2 h-5 w-5" />
        </button>
      )}
    </Tag>
  )
}

export const SearchPill = ({ children, onClick, onCloseButtonClick }) => (
  <div
    className="flex h-8 items-center justify-center rounded bg-gray-dark px-3 py-1 font-bold text-white sm:flex-none"
    onClick={onClick}>
    {children}
    <button onClick={onCloseButtonClick}>
      <XMarkIcon className="z-10 ml-2 h-5 w-5" />
    </button>
  </div>
)
