import debounce from "lodash/debounce"
import React, { useEffect, useState } from "react"

import { updateLocationSearchText } from "../../utils/utils"

import { useSearchPageContext } from "./SearchPageContext"

const MapWithPins = () => {
  const { mapCenter, setMapCenter, setBoundingBox, mapZoom, setMapZoom, boundingBox, mapPins } = useSearchPageContext()
  const [map, setMap] = useState(null)

  useEffect(() => {
    async function initMap() {
      const { Map } = await window.google.maps.importLibrary("maps")
      const map = new Map(document.getElementById("map"), {
        center: mapCenter,
        zoom: mapZoom,
        disableDefaultUI: true,
        zoomControl: true,
        mapId: "cfedcf9606fee63d"
      })

      let initialLoad = true

      window.google.maps.event.addListenerOnce(map, "tilesloaded", function () {
        map.setZoom(mapZoom)
        map.addListener(
          "idle",
          debounce(() => {
            const northEast = map.getBounds().getNorthEast()
            const southWest = map.getBounds().getSouthWest()
            const newBoundingBox = [northEast.lat(), northEast.lng(), southWest.lat(), southWest.lng()]
            if (JSON.stringify(newBoundingBox) !== JSON.stringify(boundingBox)) {
              setBoundingBox(newBoundingBox)
            }

            const newCenter = map.getCenter()
            if (newCenter.lat() === mapCenter.lat && newCenter.lng() === mapCenter.lng) {
              // do nothing
            } else {
              const newCenterObject = { lat: newCenter.lat(), lng: newCenter.lng() }
              localStorage.setItem("mapCenter", JSON.stringify(newCenterObject))
              setMapCenter(newCenterObject)
            }

            setMapZoom(map.getZoom())
            if (initialLoad) {
              updateLocationSearchText([newCenter.lat(), newCenter.lng()])
              initialLoad = false
            }
          }, 250)
        )
      })

      setMap(map)
      document.dispatchEvent(new CustomEvent("googlePlacesLoaded"))
    }

    initMap()
  }, [])

  useEffect(() => {
    if (!map) return

    const onNewMapCenter = (e) => {
      console.log("newMapCenter event", e.detail)
      map.setCenter(e.detail)
      map.setZoom(12)

      const northEast = map.getBounds().getNorthEast()
      const southWest = map.getBounds().getSouthWest()
      const newBoundingBox = [northEast.lat(), northEast.lng(), southWest.lat(), southWest.lng()]
      setBoundingBox(newBoundingBox)
    }

    const onNewMapZoom = (e) => {
      console.log("newMapZoom event", e.detail)
      map.setZoom(e.detail)

      const northEast = map.getBounds().getNorthEast()
      const southWest = map.getBounds().getSouthWest()
      const newBoundingBox = [northEast.lat(), northEast.lng(), southWest.lat(), southWest.lng()]
      if (JSON.stringify(newBoundingBox) !== JSON.stringify(boundingBox)) {
        setBoundingBox(newBoundingBox)
      }
    }

    document.addEventListener("newMapCenter", onNewMapCenter)
    document.addEventListener("newMapZoom", onNewMapZoom)

    return () => {
      document.removeEventListener("newMapCenter", onNewMapCenter)
      document.removeEventListener("newMapZoom", onNewMapZoom)
    }
  }, [map])

  useEffect(() => {
    async function renderMarkers() {
      const { AdvancedMarkerElement, PinElement } = await window.google.maps.importLibrary("marker")

      Object.entries(mapPins).forEach((item) => {
        const pin = item[1]
        const pinBackground = new PinElement({
          background: "#0B3954",
          borderColor: "#0B3954",
          glyphColor: "rgba(255, 255, 255, 0.64)"
        })
        const marker = new AdvancedMarkerElement({
          position: { lat: pin.lat, lng: pin.lng },
          content: pinBackground.element,
          title: pin.name,
          id: `marker-${pin.slug}`,
          map
        })
        marker.addListener("click", (e) => {
          e.domEvent.preventDefault()
          const searchCard = document.getElementById(pin.slug)
          searchCard?.scrollIntoView({ behavior: "smooth" })
          searchCard?.focus()
        })
        pin.pinBackground = pinBackground
        pin.marker = marker
      })
    }

    if (window.google && map && mapPins) {
      renderMarkers()
    }
  }, [map, mapPins])

  return <div id="map" className="h-full w-full" />
}

export default MapWithPins
