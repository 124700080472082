import { MapPinIcon, VideoCameraIcon } from "@heroicons/react/24/outline"
import React, { useState } from "react"

import { CheckBox, SearchInput } from "../../components/shared/Inputs"
import Typography from "../../components/shared/Typography"

import { useSearchPageContext } from "./SearchPageContext"

const defaultNumTherapiesShown = 4

const TherapiesFilters = () => {
  const { therapyCategories, selectedTherapies, setSelectedTherapies, setSelectedLocations, inPersonCounts } =
    useSearchPageContext()

  const inPersonTherapies = therapyCategories.map((therapy) => therapy.name)
  const [filteredInPersonTherapies, setFilteredInPersonTherapies] = useState(inPersonTherapies)
  const [numInPersonTherapiesShown, setNumInPersonTherapiesShown] = useState(defaultNumTherapiesShown)
  const canToggleShowMoreInPerson = inPersonTherapies.length > defaultNumTherapiesShown

  const virtualTherapies = therapyCategories.filter((therapy) => !therapy.inPersonOnly).map((therapy) => therapy.name)
  const [filteredVirtualTherapies, setFilteredVirtualTherapies] = useState(virtualTherapies)
  const [numVirtualTherapiesShown, setNumVirtualTherapiesShown] = useState(defaultNumTherapiesShown)
  const canToggleShowMoreVirtual = virtualTherapies.length > defaultNumTherapiesShown

  const [searchValue, setSearchValue] = useState("")

  const virtualCounts = therapyCategories.reduce((acc, therapy) => {
    acc[therapy.name] = therapy.count
    return acc
  }, {})

  const inPersonTherapiesShown = filteredInPersonTherapies
    .filter((item) => !!inPersonCounts[item] || selectedTherapies.includes(item + " (in person)") || searchValue !== "")
    .sort((a, b) => {
      const aSelected = selectedTherapies.includes(a + " (in person)") ? 1 : 0
      const bSelected = selectedTherapies.includes(b + " (in person)") ? 1 : 0

      if (aSelected !== bSelected) return bSelected - aSelected

      return (inPersonCounts[b] || 0) - (inPersonCounts[a] || 0)
    })

  const virtualTherapiesShown = filteredVirtualTherapies
    .filter((item) => !!virtualCounts[item])
    .sort((a, b) => {
      const aSelected = selectedTherapies.includes(a + " (virtual)") ? 1 : 0
      const bSelected = selectedTherapies.includes(b + " (virtual)") ? 1 : 0

      if (aSelected !== bSelected) return bSelected - aSelected

      return (virtualCounts[b] || 0) - (virtualCounts[a] || 0)
    })
    .slice(0, numVirtualTherapiesShown)

  return (
    <>
      <SearchInput
        type="search"
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck={false}
        maxLength={512}
        containerClassName="w-full"
        explanatorySubtext="Ex “Massage Therapy” or “Reiki”"
        value={searchValue}
        onChange={(event) => {
          setSearchValue(event.target.value)
          const newVirtualTherapies = virtualTherapies.filter((therapy) =>
            therapy.toLowerCase().includes(event.target.value.toLowerCase())
          )
          setFilteredVirtualTherapies(newVirtualTherapies)

          const newInPersonTherapies = inPersonTherapies.filter((therapy) =>
            therapy.toLowerCase().includes(event.target.value.toLowerCase())
          )
          setFilteredInPersonTherapies(newInPersonTherapies)
        }}
      />
      <>
        <Typography variant="capitalHeading" as="h3" className="mt-4 flex items-center gap-1">
          In-person
          <MapPinIcon className="h-5 w-5" />
        </Typography>
        <ul className="mt-3 flex flex-col gap-1.5">
          {inPersonTherapiesShown.slice(0, numInPersonTherapiesShown).map((item) => {
            const count = inPersonCounts[item] || 0
            const itemWithParens = item + " (in person)"

            return (
              <li key={item}>
                <CheckBox
                  id={`in-person-checkbox-${item}`}
                  className="mb-0"
                  checked={selectedTherapies.includes(itemWithParens)}
                  onChange={() => {
                    if (selectedTherapies.includes(itemWithParens)) {
                      const filteredTherapies = selectedTherapies.filter((therapy) => therapy !== itemWithParens)
                      if (filteredTherapies.filter((therapy) => therapy.includes("(in person)")).length === 0) {
                        setSelectedLocations(["office", "home", "virtual"])
                      }
                      setSelectedTherapies(filteredTherapies)
                    } else {
                      setSelectedLocations(["office", "home"])
                      const newTherapies = [...selectedTherapies, itemWithParens]
                      if (selectedTherapies.filter((therapy) => therapy.includes("(virtual)")).length > 0) {
                        setSelectedTherapies(newTherapies.filter((therapy) => !therapy.includes("(virtual)")))
                      } else {
                        setSelectedTherapies(newTherapies)
                      }
                    }
                  }}
                  label={`${item} (${count > 99 ? "99+" : count})`}
                />
              </li>
            )
          })}
          {inPersonTherapiesShown.length === 0 && <li>No options matching</li>}
        </ul>
        {canToggleShowMoreInPerson && inPersonTherapiesShown.length > 0 && (
          <button
            onClick={() => {
              setNumInPersonTherapiesShown(
                numInPersonTherapiesShown < inPersonTherapiesShown.length
                  ? numInPersonTherapiesShown + 10
                  : defaultNumTherapiesShown
              )
            }}
            className="mt-1.5 font-bold text-teal">
            {numInPersonTherapiesShown < inPersonTherapiesShown.length ? "Load 10 more" : "See less"}
          </button>
        )}
      </>
      <Typography variant="capitalHeading" as="h3" className="mt-4 flex items-center gap-1">
        Virtual
        <VideoCameraIcon className="h-5 w-5" />
      </Typography>
      <ul className="mt-3 flex flex-col gap-1.5">
        {virtualTherapiesShown.map((item) => {
          const itemWithParens = item + " (virtual)"

          return (
            <li key={item}>
              <CheckBox
                id={`virtual-checkbox-${item}`}
                className="mb-0"
                checked={selectedTherapies.includes(itemWithParens)}
                onChange={() => {
                  if (selectedTherapies.includes(itemWithParens)) {
                    const filteredTherapies = selectedTherapies.filter((therapy) => therapy !== itemWithParens)
                    if (filteredTherapies.filter((therapy) => therapy.includes("(virtual)")).length === 0) {
                      setSelectedLocations(["office", "home", "virtual"])
                    }
                    setSelectedTherapies(filteredTherapies)
                  } else {
                    setSelectedLocations(["virtual"])
                    const newTherapies = [...selectedTherapies, itemWithParens]
                    if (selectedTherapies.filter((therapy) => therapy.includes("(in person)")).length > 0) {
                      setSelectedTherapies(newTherapies.filter((therapy) => !therapy.includes("(in person)")))
                    } else {
                      setSelectedTherapies(newTherapies)
                    }
                  }
                }}
                label={
                  item + (virtualCounts[item] ? ` (${virtualCounts[item] > 99 ? "99+" : virtualCounts[item]})` : " (0)")
                }
              />
            </li>
          )
        })}
        {virtualTherapiesShown.length === 0 && <li>No options matching</li>}
      </ul>
      {canToggleShowMoreVirtual && virtualTherapiesShown.length > 0 && (
        <button
          onClick={() => {
            setNumVirtualTherapiesShown(
              numVirtualTherapiesShown < filteredVirtualTherapies.length
                ? numVirtualTherapiesShown + 10
                : defaultNumTherapiesShown
            )
          }}
          className="mt-1.5 font-bold text-teal">
          {numVirtualTherapiesShown < filteredVirtualTherapies.length ? "Load 10 more" : "See less"}
        </button>
      )}
    </>
  )
}

export default TherapiesFilters
