import React from "react"

import { CloseButton } from "../../components/shared/Buttons"
import { ButtonLink } from "../../components/shared/Links"
import Typography from "../../components/shared/Typography"

const QuizInterstitial = ({ onDismiss }) => (
  <div className="relative max-w-5xl rounded-lg border border-teal bg-white p-8">
    <CloseButton className="absolute right-4 top-4 h-6 w-6" onClick={onDismiss} />
    <Typography variant="h3" as="h2">
      Get matched with the right practitioner now. It’s free and only takes 1 minute.
    </Typography>
    <div className="mt-4">Answer a few questions to help us find you the best fit for your needs.</div>
    <ButtonLink className="mt-4" href="/quizzes/new">
      Match me now
    </ButtonLink>
  </div>
)

export default QuizInterstitial
