export const filtersString = ({
  selectedTherapies,
  selectedHealthIssues,
  selectedLocations,
  offersFreeConsult,
  selectedLanguages,
  selectedAges
}) => {
  let selectedTherapyFilters = selectedTherapies
    ?.map((therapy) => `therapies:"${therapy.replace(/\s*\(.*\)$/, "")}"`)
    ?.join(" OR ")
  selectedTherapyFilters = selectedTherapyFilters ? `(${selectedTherapyFilters})` : null
  let selectedHealthIssueFilters = selectedHealthIssues
    ?.map((condition) => `health_issues:"${condition}"`)
    ?.join(" AND ")
  selectedHealthIssueFilters = selectedHealthIssueFilters ? `(${selectedHealthIssueFilters})` : null
  let selectedLocationFilters = selectedLocations?.map((location) => `location_types:"${location}"`)?.join(" OR ")
  selectedLocationFilters = selectedLocationFilters ? `(${selectedLocationFilters})` : null
  const freeConsultFilter = offersFreeConsult ? "free_consult:true" : null
  const selectedLanguagesFilters = selectedLanguages?.map((language) => `languages:"${language}"`)?.join(" OR ")
  const selectedAgeWorkWithFilters = selectedAges?.map((age) => `age_work_withs:"${age}"`)?.join(" OR ")
  return [
    selectedTherapyFilters,
    selectedHealthIssueFilters,
    selectedLocationFilters,
    freeConsultFilter,
    selectedLanguagesFilters,
    selectedAgeWorkWithFilters
  ]
    .filter(Boolean)
    .join(" AND ")
}

export const shouldShowMap = ({ isTablet, mapCenter, selectedLocations }) =>
  (typeof window === "undefined" && !isTablet) ||
  (!!mapCenter &&
    !isTablet &&
    (selectedLocations.includes("home") || selectedLocations.includes("office") || selectedLocations.length === 0))

export const isVirtualView = ({ selectedLocations }) =>
  selectedLocations.includes("virtual") && selectedLocations.length === 1

export const getSearchOptions = ({
  filters,
  boundingBox,
  radiusInMiles,
  mapCenter,
  isTablet,
  selectedLocations,
  currentPage
}) => {
  const options = {
    page: currentPage,
    filters,
    facets: ["health_issues", "languages", "age_work_withs"]
  }

  const virtualView = isVirtualView({ selectedLocations })
  const showMap = shouldShowMap({ isTablet, mapCenter, selectedLocations })

  if (showMap && boundingBox) {
    options.insideBoundingBox = [boundingBox]
  } else if (isTablet && mapCenter) {
    if (radiusInMiles) {
      options.aroundRadius = radiusInMiles * 1609
      options.aroundPrecision = 5 * 1609
    }
    options.aroundLatLng = [mapCenter.lat, mapCenter.lng].join(",")
  } else if (virtualView && !isTablet) {
    // no early return or additional options for virtual web view
  } else {
    return null
  }

  return options
}

export const updateUrl = ({
  query,
  radiusInMiles,
  mapCenter,
  mapZoom,
  selectedTherapies,
  selectedHealthIssues,
  selectedLocations,
  offersFreeConsult,
  selectedLanguages,
  selectedAges,
  isMobile,
  poppingState,
  setPoppingState,
  boundingBox
}) => {
  const queryParams = new URLSearchParams()
  if (query) queryParams.set("query", query)
  if (radiusInMiles && isMobile) queryParams.set("radius", radiusInMiles)
  if (mapCenter) queryParams.set("mapCenter", [mapCenter.lat, mapCenter.lng].join(","))
  if (selectedTherapies.length) queryParams.set("therapies", selectedTherapies.join(","))
  if (selectedHealthIssues.length) queryParams.set("healthIssues", selectedHealthIssues.join(","))
  if (selectedLocations.length) queryParams.set("locations", selectedLocations.join(","))
  if (offersFreeConsult) queryParams.set("freeConsult", offersFreeConsult)
  if (selectedLanguages.length) queryParams.set("languages", selectedLanguages.join(","))
  if (selectedAges.length) queryParams.set("ages", selectedAges.join(","))
  if (mapZoom) queryParams.set("mapZoom", mapZoom)
  if (boundingBox) queryParams.set("boundingBox", boundingBox.join(","))

  const currentParams = new URLSearchParams(window.location.search)
  if (currentParams.has("debug")) queryParams.set("debug", "true")

  if (poppingState) {
    setPoppingState(false)
    return
  }

  window.history.pushState(
    { queryString: queryParams.toString() },
    "",
    `/practitioner-search?${queryParams.toString()}`
  )
}

export const performSearch = (
  {
    searchIndex,
    selectedTherapies,
    selectedHealthIssues,
    selectedLocations,
    offersFreeConsult,
    selectedLanguages,
    selectedAges,
    currentPage,
    boundingBox,
    showMap,
    radiusInMiles,
    mapCenter,
    isTablet,
    query,
    similarQuery,
    additionalFilters,
    virtualFallback,
    hitsPerPage
  },
  onSuccess
) => {
  if (hitsPerPage === undefined) hitsPerPage = isTablet ? 3 : 25

  const filters = filtersString({
    selectedTherapies,
    selectedHealthIssues,
    selectedLocations,
    offersFreeConsult,
    selectedLanguages,
    selectedAges
  })

  const searchOptions = getSearchOptions({
    currentPage,
    filters,
    boundingBox,
    showMap,
    radiusInMiles,
    mapCenter,
    isTablet,
    selectedLocations
  })

  if (mapCenter === null || searchOptions === null) {
    return // don't trigger a search if the search options state isn't fully initialized
  }

  searchOptions.hitsPerPage = hitsPerPage

  if (similarQuery) {
    searchOptions.similarQuery = query
  }

  if (additionalFilters) {
    if (searchOptions.filters) {
      searchOptions.filters = `${filters} AND ${additionalFilters}`
    } else {
      searchOptions.filters = additionalFilters
    }
  }

  if (virtualFallback) {
    delete searchOptions.aroundLatLng
    delete searchOptions.aroundRadius
  }

  searchOptions.attributesToHighlight = []

  searchIndex.search(query, searchOptions).then((searchResponse) => {
    onSuccess(searchResponse)
  })
}

export const doubleBoundingBoxSize = (mapCenter, boundingBox) => {
  if (!mapCenter || !boundingBox) return null

  const deltaX = (boundingBox[2] - boundingBox[0]) / 2
  const deltaY = (boundingBox[1] - boundingBox[3]) / 2

  const newTopLeft = [mapCenter.lat - deltaX * 2, mapCenter.lng + deltaY * 2]
  const newBottomRight = [mapCenter.lat + deltaX * 2, mapCenter.lng - deltaY * 2]

  return newTopLeft.concat(newBottomRight)
}
