import algoliasearch from "algoliasearch/lite"
import differenceBy from "lodash/differenceBy"
import React, { createContext, useCallback, useContext, useEffect, useState } from "react"

import useWindowSize from "../../hooks/useWindowSize"

import { doubleBoundingBoxSize, isVirtualView, performSearch, shouldShowMap, updateUrl } from "./searchUtils"

const SearchPageContext = createContext()

const searchClient = algoliasearch(
  process?.env?.ALGOLIA_HEALME_SEARCH_APPLICATION_ID,
  process?.env?.ALGOLIA_HEALME_SEARCH_CLIENT_API_KEY
)

const indexName = "Practice_production"
const countsIndex = searchClient.initIndex(indexName)
const searchIndex = searchClient.initIndex(indexName)

export const SearchPageProvider = ({
  children,
  therapyCategories,
  healthIssues,
  defaultLatLng,
  languages,
  ages,
  mobile,
  tablet
}) => {
  const { width } = useWindowSize()
  const [isMobile, setIsMobile] = useState(mobile)
  const [isTablet, setIsTablet] = useState(tablet)

  useEffect(() => {
    if (width) {
      setIsMobile(width ? width < 768 : isMobile)
      setIsTablet(width ? width < 1150 : isTablet)
    }
  }, [width])

  const [urlParams] = useState(
    typeof window !== "undefined" ? new URLSearchParams(window.location.search) : new URLSearchParams()
  )
  const defaultQuery = urlParams.get("query") || ""
  const defaultRadius = isTablet ? parseInt(urlParams.get("radius")) || 25 : null

  const mapCenterParam = urlParams.get("mapCenter")
  const latLng = mapCenterParam?.split(",").map((coord) => parseFloat(coord))
  const defaultMapCenter = latLng
    ? { lat: latLng[0], lng: latLng[1] }
    : typeof localStorage !== "undefined"
    ? JSON.parse(localStorage.getItem("mapCenter"))
    : null
  const defaultTherapies = urlParams.get("therapies")?.split(",") || []
  const defaultHealthIssues = urlParams.get("healthIssues")?.split(",") || []
  const defaultLocations = urlParams.get("locations")?.split(",") || ["office", "home", "virtual"]
  const defaultOffersFreeConsult = urlParams.get("freeConsult") === "true"
  const defaultLanguages = urlParams.get("languages")?.split(",") || []
  const defaultAges = urlParams.get("ages")?.split(",") || []
  const defaultMapZoom = urlParams.get("mapZoom") ? parseFloat(urlParams.get("mapZoom")) : 12
  const defaultBoundingBox = urlParams
    .get("boundingBox")
    ?.split(",")
    .map((coord) => parseFloat(coord))

  // search state
  const [query, setQuery] = useState(defaultQuery || "")
  const [radiusInMiles, setRadiusInMiles] = useState(defaultRadius)
  const [mapCenter, setMapCenter] = useState(defaultMapCenter)
  const [boundingBox, setBoundingBox] = useState(defaultBoundingBox)
  const [selectedTherapies, setSelectedTherapies] = useState(defaultTherapies)
  const [selectedHealthIssues, setSelectedHealthIssues] = useState(defaultHealthIssues)
  const [selectedLocations, setSelectedLocations] = useState(defaultLocations)
  const [offersFreeConsult, setOffersFreeConsult] = useState(defaultOffersFreeConsult)
  const [selectedLanguages, setSelectedLanguages] = useState(defaultLanguages)
  const [selectedAges, setSelectedAges] = useState(defaultAges)
  const [mapZoom, setMapZoom] = useState(defaultMapZoom)

  const [inPersonCounts, setInPersonCounts] = useState({})
  const [healthIssueCounts, setHealthIssueCounts] = useState(null)
  const [languageCounts, setLanguageCounts] = useState(null)
  const [ageCounts, setAgeCounts] = useState(null)
  const [hits, setHits] = useState(null)
  const [nbHits, setNbHits] = useState(0)
  const [isLastPage, setIsLastPage] = useState(true)
  const [currentPage, setCurrentPage] = useState(0)
  const [isFetchingNextPage, setIsFetchingNextPage] = useState(false)
  const [loading, setLoading] = useState(true)
  const [poppingState, setPoppingState] = useState(false)
  const [previousSearch, setPreviousSearch] = useState(null)
  const [googlePlacesLoaded, setGooglePlacesLoaded] = useState(false)
  const [shouldDynamicZoom, setShouldDynamicZoom] = useState(true)
  const [performedFallbackSearches, setPerformedFallbackSearches] = useState(false)
  const [mapPins, setMapPins] = useState()
  const showMap = shouldShowMap({ isTablet, mapCenter, selectedLocations })
  const virtualView = isVirtualView({ selectedLocations })
  const locationOptions = ["office", "home", "virtual"]

  const [similarQueryFallbackState, setSimilarQueryFallbackState] = useState({ hits: [], nbHits: 0 })
  const [nearbyFallbackState, setNearbyFallbackState] = useState({ hits: [], nbHits: 0 })
  const [virtualFallbackState, setVirtualFallbackState] = useState({ hits: [], nbHits: 0 })

  useEffect(() => {
    const onPopState = () => {
      const urlParams = new URLSearchParams(window.location.search)
      setSelectedTherapies(urlParams.get("therapies")?.split(",") || [])
      setSelectedHealthIssues(urlParams.get("healthIssues")?.split(",") || [])
      setSelectedLocations(urlParams.get("locations")?.split(",") || ["office", "home", "virtual"])
      setOffersFreeConsult(urlParams.get("freeConsult") === "true")
      setSelectedLanguages(urlParams.get("languages")?.split(",") || [])
      setSelectedAges(urlParams.get("ages")?.split(",") || [])
      setQuery(urlParams.get("query") || "")
      setRadiusInMiles(isMobile ? urlParams.get("radius") || 25 : null)

      const mapCenterParam = urlParams.get("mapCenter")
      if (mapCenterParam) {
        const latLng = mapCenterParam.split(",").map((coord) => parseFloat(coord))
        setMapCenter(isMobile ? { lat: latLng[0], lng: latLng[1] } : null)
      }
      setBoundingBox(
        urlParams
          .get("boundingBox")
          ?.split(",")
          .map((coord) => parseFloat(coord))
      )

      setPoppingState(true)
    }

    const onNewMapCenter = (e) => {
      setMapCenter(e.detail)
      setMapZoom(12)
      setShouldDynamicZoom(true)
      localStorage.setItem("mapCenter", JSON.stringify(e.detail))
    }

    window.addEventListener("popstate", onPopState)
    document.addEventListener("newMapCenter", onNewMapCenter)

    async function initPlaces() {
      await window.google.maps.importLibrary("places")
      setGooglePlacesLoaded(true)
      document.dispatchEvent(new CustomEvent("googlePlacesLoaded"))
    }

    initPlaces()

    if (!isMobile && !isTablet) {
      fetch("/practitioner-search/map_pins")
        .then((response) => {
          response.json().then((data) => {
            setMapPins(data)
          })
        })
        .catch((error) => {
          console.error("Error fetching map pins", error)
        })
    }

    return () => {
      window.removeEventListener("popstate", onPopState)
      document.removeEventListener("newMapCenter", onNewMapCenter)
    }
  }, [])

  useEffect(() => {
    const searchParams = {
      searchIndex,
      selectedTherapies,
      selectedHealthIssues,
      selectedLocations,
      offersFreeConsult,
      selectedLanguages,
      selectedAges,
      currentPage,
      boundingBox,
      showMap,
      radiusInMiles,
      mapCenter,
      isTablet,
      query
    }

    performSearch(searchParams, (searchResponse) => {
      const { hits, nbHits, facets, nbPages, page } = searchResponse

      const searchString =
        selectedTherapies +
        selectedHealthIssues +
        selectedLocations +
        offersFreeConsult +
        selectedLanguages +
        selectedAges +
        mapCenter.lat +
        mapCenter.lng +
        radiusInMiles +
        boundingBox +
        query

      let newSearch = false
      if (searchString !== previousSearch) {
        newSearch = true
        setCurrentPage(0)
        setPreviousSearch(searchString)
        setHits([])
        setLoading(true)
        setSimilarQueryFallbackState({ hits: [], nbHits: 0 })
        setNearbyFallbackState({ hits: [], nbHits: 0 })
        setVirtualFallbackState({ hits: [], nbHits: 0 })
      }

      if (facets?.health_issues) setHealthIssueCounts(facets.health_issues)
      if (facets?.languages) setLanguageCounts(facets.languages)
      if (facets?.age_work_withs) setAgeCounts(facets.age_work_withs)

      setNbHits(nbHits)
      if (newSearch || page === 0) {
        setHits(hits)
      } else {
        setHits((prevHits) => [...prevHits, ...hits])
      }
      setSimilarQueryFallbackState({ hits: [], nbHits: 0 })
      setNearbyFallbackState({ hits: [], nbHits: 0 })
      setVirtualFallbackState({ hits: [], nbHits: 0 })
      setPerformedFallbackSearches(false)
      setIsLastPage(page + 1 >= nbPages)
      setIsFetchingNextPage(false)
      setLoading(false)

      if (searchParams.showMap && shouldDynamicZoom) {
        let newZoom
        if (nbHits <= 5 && mapZoom > 9) {
          newZoom = mapZoom - 1
        } else if (nbHits > 50 && mapZoom < 13) {
          newZoom = mapZoom + 1
        }
        if (newZoom !== undefined) {
          setMapZoom(newZoom)
          const event = new CustomEvent("newMapZoom", { detail: newZoom })
          document.dispatchEvent(event)
        }
      }

      setShouldDynamicZoom(false)
      if (page === 0) window.scrollTo(0, 0)

      updateUrl({
        query,
        radiusInMiles,
        mapCenter,
        boundingBox,
        mapZoom,
        selectedTherapies,
        selectedHealthIssues,
        selectedLocations,
        offersFreeConsult,
        selectedLanguages,
        selectedAges,
        isMobile,
        poppingState,
        setPoppingState
      })
    })
  }, [
    query,
    boundingBox,
    radiusInMiles,
    selectedTherapies,
    selectedHealthIssues,
    selectedLocations,
    selectedLanguages,
    selectedAges,
    mapCenter,
    showMap,
    offersFreeConsult,
    currentPage
  ])

  useEffect(() => {
    const countsSearchParams = {
      filters: "location_types:office OR location_types:home",
      hitsPerPage: 100
    }

    if (boundingBox) {
      countsSearchParams.insideBoundingBox = [boundingBox]
    } else if (mapCenter && radiusInMiles) {
      countsSearchParams.aroundLatLng = `${mapCenter.lat},${mapCenter.lng}`
      countsSearchParams.aroundRadius = radiusInMiles * 1609
    } else {
      return
    }

    countsIndex.search("", countsSearchParams).then(({ hits }) => {
      const counts = hits.reduce((acc, hit) => {
        hit.therapies?.forEach((therapy) => {
          acc[therapy] = acc[therapy] ? acc[therapy] + 1 : 1
        })
        return acc
      }, {})
      setInPersonCounts(counts)
    })
  }, [boundingBox])

  useEffect(() => {
    if (loading === false) {
      const timeout = setTimeout(() => {
        const savedScrollPosition = sessionStorage.getItem("scrollPosition") || 0
        sessionStorage.removeItem("scrollPosition")
        window.scrollTo(0, parseInt(savedScrollPosition, 10))
      }, 300)

      return () => clearTimeout(timeout)
    }
  }, [loading])

  const showMore = () => {
    if (isFetchingNextPage) return
    setCurrentPage((prev) => prev + 1)
    setIsFetchingNextPage(true)
  }

  const resetFilters = useCallback(() => {
    setQuery("")
    setSelectedTherapies([])
    setSelectedHealthIssues([])
    setSelectedLocations(["office", "home", "virtual"])
    setOffersFreeConsult(false)
    setSelectedLanguages([])
    setSelectedAges([])
    setSimilarQueryFallbackState({ hits: [], nbHits: 0 })
    setNearbyFallbackState({ hits: [], nbHits: 0 })
    setVirtualFallbackState({ hits: [], nbHits: 0 })
    document.dispatchEvent(new CustomEvent("resetSearchField"))
    window.scrollTo(0, 0)
  }, [])

  const searchParams = {
    searchIndex,
    selectedTherapies,
    selectedHealthIssues,
    selectedLocations,
    offersFreeConsult,
    selectedLanguages,
    selectedAges,
    currentPage,
    boundingBox,
    showMap,
    radiusInMiles,
    mapCenter,
    isTablet,
    query
  }

  const similarQueryFallbackSearch = () => {
    const newSearchParams = { ...searchParams, similarQuery: true, hitsPerPage: 25, currentPage: 0 }
    setSimilarQueryFallbackState((state) => ({ ...state, fetching: true }))
    performSearch(newSearchParams, (response) => {
      if (response.nbHits > 0) {
        const newHits = differenceBy(response.hits, hits, "objectID")
        setSimilarQueryFallbackState({ hits: newHits, nbHits: newHits.length, fetching: false, queryCompleted: true })
      } else {
        setSimilarQueryFallbackState({ hits: [], nbHits: 0, fetching: false, queryCompleted: true })
      }
    })
  }

  const nearbyFallbackSearch = () => {
    const newSearchParams = {
      ...searchParams,
      boundingBox: doubleBoundingBoxSize(searchParams.mapCenter, searchParams.boundingBox),
      radiusInMiles: searchParams.radiusInMiles * 2,
      hitsPerPage: 25,
      currentPage: 0
    }
    setNearbyFallbackState((state) => ({ ...state, fetching: true }))
    performSearch(newSearchParams, (response) => {
      if (response.nbHits > 0) {
        const newHits = differenceBy(response.hits, hits, "objectID")
        setNearbyFallbackState({ hits: newHits, nbHits: newHits.length, fetching: false, queryCompleted: true })
      } else {
        setNearbyFallbackState({ hits: [], nbHits: 0, fetching: false, queryCompleted: true })
      }
    })
  }

  const virtualFallbackSearch = () => {
    const additionalFilters = therapyCategories
      .filter((therapy) => therapy.inPersonOnly)
      .map((therapy) => `NOT therapies:"${therapy.name}"`)
      .join(" AND ")
    const virtualSearchParams = {
      ...searchParams,
      selectedLocations: ["virtual"],
      additionalFilters,
      virtualFallback: true,
      hitsPerPage: 25,
      currentPage: 0
    }

    setVirtualFallbackState((state) => ({ ...state, fetching: true }))
    performSearch(virtualSearchParams, (response) => {
      if (response.nbHits > 0) {
        const newHits = differenceBy(response.hits, hits, "objectID")
        setVirtualFallbackState({ hits: newHits, nbHits: newHits.length, fetching: false, queryCompleted: true })
      } else {
        setVirtualFallbackState({ hits: [], nbHits: 0, fetching: false, queryCompleted: true })
      }
    })
  }

  return (
    <SearchPageContext.Provider
      value={{
        radiusInMiles,
        setRadiusInMiles,
        isMobile,
        isTablet,
        therapyCategories,
        healthIssues,
        languages,
        ages,
        query,
        setQuery,
        selectedTherapies,
        setSelectedTherapies,
        selectedHealthIssues,
        setSelectedHealthIssues,
        selectedLocations,
        setSelectedLocations,
        selectedLanguages,
        setSelectedLanguages,
        selectedAges,
        setSelectedAges,
        locationOptions,
        showMap,
        virtualView,
        mapCenter,
        setMapCenter,
        mapZoom,
        setMapZoom,
        boundingBox,
        setBoundingBox,
        inPersonCounts,
        healthIssueCounts,
        languageCounts,
        ageCounts,
        hits,
        nbHits,
        defaultLatLng,
        offersFreeConsult,
        setOffersFreeConsult,
        isLastPage,
        loading,
        showMore,
        resetFilters,
        googlePlacesLoaded,
        similarQueryFallbackState,
        setSimilarQueryFallbackState,
        nearbyFallbackState,
        setNearbyFallbackState,
        virtualFallbackState,
        setVirtualFallbackState,
        similarQueryFallbackSearch,
        nearbyFallbackSearch,
        virtualFallbackSearch,
        performedFallbackSearches,
        setPerformedFallbackSearches,
        mapPins
      }}>
      {children}
    </SearchPageContext.Provider>
  )
}

export const useSearchPageContext = () => useContext(SearchPageContext)
