import React from "react"

import { CloseButton } from "../../components/shared/Buttons"
import { ButtonLink } from "../../components/shared/Links"
import Typography from "../../components/shared/Typography"

const JoinInterstitial = ({ onDismiss }) => (
  <div className="relative max-w-5xl rounded-lg border border-orange bg-orange-light p-8">
    <CloseButton className="absolute right-4 top-4 h-6 w-6" onClick={onDismiss} />
    <Typography variant="h3" as="h2">
      Are you a holistic practitioner? Apply today to feature your practice.
    </Typography>
    <ButtonLink className="mt-4" href="/pro">
      Apply now
    </ButtonLink>
  </div>
)

export default JoinInterstitial
