import { StarIcon } from "@heroicons/react/24/solid"
import React from "react"

import { searchPractitionerWebsiteUrl } from "../../@core/practitioner/practitioner.utils"
import { Button } from "../../components/shared/Buttons"
import { Divider } from "../../components/shared/Layout"
import Typography from "../../components/shared/Typography"
import { distanceInMiles } from "../../utils/utils"

import { useSearchPageContext } from "./SearchPageContext"

const formatAmount = (cents) => {
  if (cents === 0) {
    return "Free"
  } else {
    const dollars = cents / 100
    if (cents % 100 === 0) {
      return `$${dollars.toFixed(0)}`
    } else {
      return `$${dollars.toFixed(2)}`
    }
  }
}

const ReviewSection = ({ hit }) => (
  <div className="mt-2 flex items-center gap-1">
    <StarIcon className="h-5 w-5 text-orange" />
    <span className="font-bold">{hit.reviews_average === 5 ? "5.0" : hit.reviews_average}</span>
    <span className="ml-1 font-bold text-teal underline">{hit.reviews_count} verified reviews</span>
  </div>
)

const ServicesSection = ({ services }) => (
  <div className="flex flex-col gap-2">
    {services.map((service) => (
      <div key={service.id}>
        <div className="font-bold">
          <div className="line-clamp-2" dangerouslySetInnerHTML={{ __html: service.name }} />
        </div>
        <div className="text-sm">
          <span>{formatAmount(service.amount_cents)}</span> <span className="text-gray">•</span>{" "}
          <span>{service.length} min</span>
        </div>
      </div>
    ))}
  </div>
)

const SkillsSection = ({ hit }) => {
  const { selectedHealthIssues } = useSearchPageContext()
  const skills = hit.skills.filter((skill) => selectedHealthIssues.includes(skill.name) && skill.count > 0)

  if (skills.length === 0) return null

  return (
    <div className="mt-4 flex flex-wrap items-center gap-2">
      {skills.slice(0, 2).map((skill) => (
        <div key={`${hit.objectID}-skill-${skill.name}`} className="flex items-stretch justify-center text-sm">
          <div
            className={`rounded-l-lg border border-gray-light bg-gray-light px-3 py-1 ${
              skill.count === 0 ? "rounded-lg" : ""
            }`}
            dangerouslySetInnerHTML={{ __html: skill.name }}
          />
          {skill.count > 0 && (
            <div className="flex items-center rounded-r-lg border border-gray-light px-3 py-1">{skill.count}</div>
          )}
        </div>
      ))}
    </div>
  )
}

const SearchCard = ({ hit, showVirtualInsteadOfDistance = false }) => {
  const { mapCenter, virtualView, isMobile, mapPins } = useSearchPageContext()
  const services = hit.services.sort((a, b) => a.position - b.position).slice(0, 2)
  const widthResize = isMobile ? "width:104" : "width:234"

  const photoUrl = hit.user.profile_photos?.large?.webp
    ? `https://cdn.filestackcontent.com/resize=${widthResize}/${hit.user.profile_photos?.large?.webp}`
    : hit.user.photo

  const distance =
    virtualView || hit._geoloc === null
      ? null
      : showVirtualInsteadOfDistance || (hit.location_types.includes("virtual") && hit.location_types.length === 1)
      ? "Virtual"
      : `${distanceInMiles(mapCenter.lat, mapCenter.lng, hit._geoloc.lat, hit._geoloc.lng).toFixed(1)} mi`

  const websiteUrl = searchPractitionerWebsiteUrl(hit)

  return (
    <a
      onClick={() => window.sessionStorage.setItem("scrollPosition", window.scrollY)}
      id={hit.user.slug}
      href={websiteUrl}
      onMouseEnter={() => {
        const marker = mapPins && mapPins[Number(hit.objectID)]?.marker
        const pinBackground = mapPins && mapPins[Number(hit.objectID)]?.pinBackground

        if (!marker || !pinBackground) return
        marker.zIndex = 1
        pinBackground.background = "rgb(var(--color-orange))"
        pinBackground.borderColor = "rgb(var(--color-orange))"
      }}
      onMouseLeave={() => {
        const marker = mapPins && mapPins[Number(hit.objectID)]?.marker
        const pinBackground = mapPins && mapPins[Number(hit.objectID)]?.pinBackground

        if (!marker || !pinBackground) return
        marker.zIndex = 0
        pinBackground.background = "#0B3954"
        pinBackground.borderColor = "#0B3954"
      }}
      className="group mb-8 block w-full max-w-5xl rounded-lg border-gray-light bg-white p-8 focus:outline-none focus:ring-2 focus:ring-blue min_md:hover:border-gray min_md:hover:shadow sm:-ml-6 sm:mb-0 sm:box-content sm:rounded-none sm:border-b sm:p-6 min_sm:border">
      <div className="relative flex items-center gap-8">
        {window.location.search.includes("debug") && (
          <div className="absolute -right-8 -top-8 rounded-lg border border-gray bg-red-light px-2 py-0.5 text-xs font-extrabold">
            <div>Score: {hit.ranking_score}</div>
            <div>{hit.reviews_count} reviews</div>
            {hit.doctor ? <div>x3 for doctor</div> : hit.licensed ? <div>x2 for licensed</div> : null}
          </div>
        )}
        <div className="relative flex-none">
          {!virtualView && mapCenter && hit._geoloc && (
            <div className="absolute -left-2 -top-2 z-10 rounded-lg border border-gray bg-gray-ultralight px-2 py-0.5 text-xs font-extrabold sm:hidden">
              {distance}
            </div>
          )}
          <div className="h-[232px] w-[200px] flex-none overflow-hidden rounded-lg bg-gray-ultralight sm:h-[104px] sm:w-[104px]">
            <img
              src={photoUrl}
              alt={hit.user.name}
              className="h-full w-full rounded-lg object-cover transition-all duration-500 min_md:group-hover:scale-110"
              loading="lazy"
            />
          </div>
          {!isMobile && (
            <div className="mt-4 flex gap-4 sm:hidden">
              {hit.gallery_images.slice(0, 2).map((image) => (
                <img
                  key={image.id}
                  src={`https://cdn.filestackcontent.com/resize=width:92/${image.filestack_photo?.square?.webp}`}
                  alt={hit.user.name}
                  className="h-[92px] w-[92px] flex-none rounded-lg object-cover"
                  loading="lazy"
                />
              ))}
            </div>
          )}
        </div>
        <div className="overflow-hidden">
          <h2 className="flex items-center gap-2">
            <div
              className="line-clamp-1 min_md:group-hover:underline"
              dangerouslySetInnerHTML={{ __html: hit.user.name }}
            />
            <img className="h-6 w-6 text-teal" src="/images/icons/verified.svg" alt="Verified" />
          </h2>
          <Typography variant="h5" as="h2">
            <div
              className="line-clamp-2 min_md:group-hover:underline"
              dangerouslySetInnerHTML={{ __html: hit.user.title }}
            />
          </Typography>
          <div className="sm:hidden">
            <ReviewSection hit={hit} />
            <div className="text-gray-dark">{hit.city_state}</div>
            <Divider />
            <ServicesSection services={services} />
            <SkillsSection hit={hit} />
            <div
              className="mt-4 line-clamp-2 text-gray-dark"
              dangerouslySetInnerHTML={{ __html: hit.website_headline || hit.headline }}
            />
          </div>
        </div>
      </div>
      <div className="hidden sm:block">
        <ReviewSection hit={hit} />
        <div className="mb-4 mt-2 flex items-center gap-2">
          <div>{hit.city_state}</div>
          {!virtualView && mapCenter && hit._geoloc && (
            <div className="rounded-lg border border-gray bg-gray-ultralight px-2 py-0.5 text-xs font-extrabold">
              {distance}
            </div>
          )}
        </div>
        <ServicesSection services={services} />
        <SkillsSection hit={hit} />
        <div
          className="mt-4 line-clamp-2 text-gray-dark"
          dangerouslySetInnerHTML={{ __html: hit.website_headline || hit.headline }}
        />
      </div>
      <Button
        type="secondary"
        className="mt-4 h-10 w-full transition-all duration-500 min_md:group-hover:bg-teal min_md:group-hover:text-white"
        href={websiteUrl}>
        View website
      </Button>
    </a>
  )
}

export default SearchCard
