import React, { useState, memo } from "react"

import { CheckBox, SearchInput } from "../../components/shared/Inputs"

const FacetList = ({
  items,
  selectedItems,
  setSelectedItems,
  counts,
  searchable = false,
  searchSubtext,
  alwaysShowAll = false
}) => {
  const [filteredItems, setFilteredItems] = useState(items)
  const [isShowingMore, setIsShowingMore] = useState(false)
  const [searchValue, setSearchValue] = useState("")
  const canToggleShowMore = filteredItems.length > 4

  return (
    <>
      {searchable && (
        <SearchInput
          type="search"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck={false}
          maxLength={512}
          containerClassName="w-full"
          explanatorySubtext={searchSubtext}
          value={searchValue}
          onChange={(event) => {
            const newItems = items.filter((item) => item.toLowerCase().includes(event.target.value.toLowerCase()))
            setFilteredItems(newItems)
            setSearchValue(event.target.value)
          }}
        />
      )}
      <ul className="mt-4 flex flex-col gap-1.5">
        {filteredItems
          .filter((item) => counts && !!counts[item])
          .slice(0, isShowingMore || alwaysShowAll ? filteredItems.length : 4)
          .map((item) => (
            <li key={item}>
              <CheckBox
                id={`in-person-checkbox-${item}`}
                className="mb-0"
                checked={selectedItems.includes(item)}
                onChange={() => {
                  setSelectedItems((prevItems) => {
                    if (prevItems.includes(item)) {
                      return prevItems.filter((prevItem) => prevItem !== item)
                    } else {
                      return [...prevItems, item]
                    }
                  })
                }}
                label={counts ? `${item} (${counts[item] || 0})` : item}
              />
            </li>
          ))}
      </ul>
      {canToggleShowMore && !alwaysShowAll && (
        <button onClick={() => setIsShowingMore(!isShowingMore)} className="mt-1.5 font-bold text-teal">
          {isShowingMore ? "See less" : "See all"}
        </button>
      )}
    </>
  )
}

export default memo(FacetList)
